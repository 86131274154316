import { InjectionToken } from '@angular/core';
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { SecurityClientModuleConfiguration } from './model/security-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<SecurityClientModuleConfiguration>('module configuration');

export const SECURITY_DEFINITION: IAscModuleDefinition = {
   moduleId: 'SECURITY',
   displayName: 'Sicherheit',
   moduleRoute: {
      loadChildren: () => import('src/modules/security/security.module').then(m => m.SecurityModule)
   },
   settingsRoute: {
      path: 'settings/security',
      loadChildren: () => import('src/modules/security-settings/security-settings.module').then(m => m.SecuritySettingsModule)
   }
};